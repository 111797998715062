import React, { useRef } from "react";
import Header from "../../elements/Header/Header";
// basics
import Button from "../../basics/Button/Button";
// elements
import Footer from "../../elements/Footer/Footer";
import HeroImage from "../../../resources/images/building_1.jpg";
import DealImage from "../../../resources/images/deal.jpg";
import ContactImage from "../../../resources/images/contact.jpg";
import TextImage from "../../../resources/images/text.jpg";
import StepsContainer from "../../elements/StepsContainer/StepsContainer";
// styles
import styles from "./HomePage.module.css";
import ContactContainer from "../../elements/ContactContainer/ContactContainer";
import { BsWhatsapp } from "react-icons/bs";

const HomePage = () => {
  const contactRef = useRef(null);

  const scroll = (ref) => {
    ref?.current?.scrollIntoView();
  };

  return (
    <div className="page">
      <Header />
      <div className={`${styles.heroContainer}`}>
        <div className={`${styles.heroContent}`}>
          <div className="container">
            <h2>
              Huis verkopen zonder makelaar, of gratis uw huis laten schatten?
            </h2>
            <p>
              Uw huis snel en gemakkelijk verkopen zonder makelaar? Bel ons of
              stuur een bericht via WhatsApp. Stuur daarbij enkele foto’s van
              het pand. Daarna komen wij het pand bezoeken en geven wij ons
              beste bod, hierbij geven we ook meteen een voorschot.
            </p>
            <Button
              label="Maak een afspraak"
              onClick={() => scroll(contactRef)}
            />
          </div>
        </div>
        <div className={`${styles.heroOverlay}`}></div>
        <img src={HeroImage} alt="hero_img" />
      </div>

      <section
        className={`${styles.sectionContainer} ${styles.info}`}
        id="info"
      >
        <StepsContainer />
      </section>

      <section id="werkwijze">
        <div className="container">
          <h2>Werkwijze</h2>
          <p>
            Bij de verkoop van een huis komt heel veel kijken. Er komen ook
            verschillende partijen aan te pas. Als je dit via een makelaar doet,
            komt er nog een extra partij bij. U moet ook een deel commissie
            afstaan waardoor u niet de volledige waarde van uw huis krijgt.
          </p>
          <p>
            Wij Kopen Meteen Uw Huis zorgt ervoor dat alles veel gemakkelijker
            gaat. Door een simpel berichtje via WhatsApp te sturen met enkele
            gegevens en foto’s kunnen wij u een prijsinschatting geven van de
            waarde van uw huis. Bij ons komt er geen extra partij aan te pas.
          </p>
          <p>
            Beslist u om met ons de verkoop aan te gaan? Dan nemen wij alle
            zorgen van het papierwerk weg en regelen wij alles voor u! Wij
            regelen zelfs de verhuis naar uw nieuwe woning zodat u in alle rust
            kan intrekken na de verkoop van uw huis. Heeft u nog geen nieuwe
            woning gevonden of wilt u een andere regeling? Alles is bespreekbaar
            dus aarzel niet om ons hier een voorstel voor te doen.
          </p>
          <div className="row">
            <div className={`${styles.serviceContent}`}>
              <div className={`${styles.seriviceImageWrapper}`}>
                <img src={TextImage} alt="service" />
              </div>
              <div className={`${styles.seriviceTextWrapper}`}>
                <h3>Bel ons of stuur een bericht via WhatsApp</h3>
                <p>
                  Stuur ons een berichtje via WhatsApp met de gegevens van uw
                  huis. Vergeet niet om foto’s van het te verkopen huis mee te
                  sturen. Wij bekijken de foto’s, maken een afspraak om het huis
                  te komen bezichtigen en maken tijdens het bezoek een
                  prijsinschatting en doen een bod. Als u het bod accepteert,
                  krijgt u meteen een voorschot !
                </p>
                <a
                  href="https://wa.me/+32499199661?text=Ik%20wil%20verkopen!%20(gelieve%20foto%27s%20van%20het%20pand%20mee%20te%20sturen)."
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${styles.ctaButton} ${styles.ctaWhatsApp}`}
                >
                  Stuur ons een berichtje via WhatsApp! <BsWhatsapp />
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className={`${styles.serviceContent} ${styles.textLeft}`}>
              <div className={`${styles.seriviceImageWrapper}`}>
                <img src={ContactImage} alt="service" />
              </div>
              <div className={`${styles.seriviceTextWrapper}`}>
                <h3>Krijg binnen 12u reactie van ons</h3>
                <p>
                  Binnen de 12 uur krijgt u antwoord van ons om een afspraak te
                  maken. Tijdens deze afspraak krijgt u meteen een
                  prijsinschatting en een bod. Dankzij onze opleiding en
                  jarenlange ervaring weten wij perfect waar in de markt uw huis
                  staat en kunnen wij u een correcte prijsinschatting bezorgen.
                  Het maakt ook niet uit hoe vol of leeg, rommelig of net uw
                  huis is. Wij kunnen dankzij onze vakkennis naar de structuur
                  van het gebouw kijken en maken op basis van onze bemerkingen
                  een correct bod.
                </p>
                <p>
                  Tijdens het bezoek krijgt u meteen een bod. Bevalt dit bod u?
                  Wij bezorgen u meteen een voorschot ! Had u toch iets anders
                  verwacht? Geen probleem, u kan ons dan een tegenbod doen of
                  vragen voor een nieuw bod.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className={`${styles.serviceContent}`}>
              <div className={`${styles.seriviceImageWrapper}`}>
                <img src={DealImage} alt="service" />
              </div>
              <div className={`${styles.seriviceTextWrapper}`}>
                <h3>Wij handelen de verkoop volledig af</h3>
                <p>
                  Was u tevreden met onze prijsinschatting en ons bod en heeft u
                  beslist om te verkopen? Bedankt voor het vertrouwen! Wij
                  starten met het in orde maken van alle nodige papieren en
                  attesten op onze kosten namelijk:
                </p>
                <div className={styles.tableSection}>
                  <span>Keuringsattest elektriciteit</span>
                  <span>EPC-attest</span>
                </div>
                <div className={styles.tableSection}>
                  <span>Bodemattest</span>
                  <span>Certificaat stookolietank</span>
                </div>
                <div className={styles.tableSection}>
                  <span>Kadastraal plan</span>
                  <span>Overstromingsgevoeligheid</span>
                </div>
                <p>
                  Nadat alle papieren in orde zijn, helpen wij u ook met uw
                  verhuis. Wij zorgen ervoor dat al uw spullen veilig in uw
                  nieuwe woning geplaatst worden. Dit is voor u ook een zorg
                  minder!
                </p>
                <p>
                  Wilt u liever uw huis verkopen zodat u geen vaste kosten meer
                  heeft en dit dan terug huren? Dit kan ook bij Wij Kopen Meteen
                  Uw Huis. Doe ons een voorstel en wij gaan na of dit
                  realistisch is. Wij luisteren naar uw noden en proberen samen
                  met u de best mogelijke oplossing te vinden.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        ref={contactRef}
        className={`${styles.sectionContainer}`}
        id="contact"
      >
        <ContactContainer />
      </section>

      <Footer />
    </div>
  );
};

export default HomePage;
