import React from "react";

import { BsCashStack } from "react-icons/bs";
import { IoMdContact } from "react-icons/io";
import { MdEditNote } from "react-icons/md";

import styles from "./StepsContainer.module.css";

const StepsContainer = () => {
  return (
    <div className={styles.stepsWrapper}>
      <div className={`container ${styles.stepsContainer}`}>
        <div className="row">
          <div
            className={`col-12 col-md-12 col-lg-4 col-xl-4 ${styles.stepWrapper}`}
          >
            <div className={styles.logoWrapper}>
              <IoMdContact />
            </div>
            <div className={styles.textWrapper}>
              <h3>Contacteer ons</h3>
              <p>
                Neem contact met ons op via Whatsapp of stuur ons een mailtje
              </p>
            </div>
          </div>
          <div
            className={`col-12 col-md-12 col-lg-4 col-xl-4 ${styles.stepWrapper}`}
          >
            <div className={styles.logoWrapper}>
              <MdEditNote />
            </div>
            <div className={styles.textWrapper}>
              <h3>Voorstel</h3>
              <p>
                Wij bieden u op basis van het gesprek en uw noden een
                gepersonaliseerd voorstel.
              </p>
            </div>
          </div>
          <div
            className={`col-12 col-md-12 col-lg-4 col-xl-4 ${styles.stepWrapper}`}
          >
            <div className={styles.logoWrapper}>
              <BsCashStack />
            </div>
            <div className={styles.textWrapper}>
              <h3>Voorschot</h3>
              <p>
                U ontvangt uw voorschot onmiddellijk en het saldo bij de
                notaris.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepsContainer;
